import React, { useEffect, useState, useContext, useCallback } from 'react'
import { useHistory, useParams, Link, useLocation } from 'react-router-dom'

import { makeStyles, withStyles } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline'
import PrintOutlined from '@material-ui/icons/PrintOutlined'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import ArrowBack from '@material-ui/icons/ArrowBack'
import DeleteForever from '@material-ui/icons/DeleteForever'

import CircularProgress from '@material-ui/core/CircularProgress'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Snackbar from '@material-ui/core/Snackbar'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import MuiAlert from '@material-ui/lab/Alert'

import { DirectionsCar } from '@material-ui/icons'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  MenuItem,
  Menu,
  IconButton,
} from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import moment from 'moment'
import { MainCheckinContext } from '../../../providers/mainCheckin'
import API from '../../../services/api'
import AgendaModal from '../../molecules/AgendaModal'
import Checkin from '../Checkin'


import { AuthContext } from '../../../providers/auth'

// Material ui - core

// Material ui - lab

import ModalType from '../../atoms/Modal'
import { getOrder, updateStatus, addItemOrder } from '../../../services/order'
import { createCheckin } from '../../../services/checkin'
import { exportToPDV } from '../../../services/export'
import { getServices } from '../../../services/services'

import 'moment/locale/pt-br'
import DrawerType from '../../atoms/Drawer'
import TaxiDog from '../Agendamento/TaxiDog'

import { TaxiDogProvider } from '../../../hooks/TaxiDogContext'
import { LineTaxidog } from './LineTaxidog'
import { Printer } from './Printer'
import { usePrinter } from '../../../store/schedule/usePrinter'
import { PetController } from '../../../services/controllers/global'
import { RecommendationsBuilder } from '../../../services/builders'
import { Action } from '../../ui/Action'
import { StartService } from './actions/StartService'
import { ChecklistPrint } from './actions/ChecklistPrint'
import { Recommendation } from '../../ui/Gaida/Recommendation'

moment.locale('pt-br')

const ITEM_HEIGHT = 48

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
  },
  avatar: {
    backgroundColor: '#CCD219',
    fontSize: '50px',
    height: '90px',
    width: '90px',
  },
  petName: {
    fontSize: '18px',
    fontWeight: 'bold',
  },
  avatarContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  list: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    color: '#ccc',
  },
  button: {
    backgroundColor: '#CCD219',
    margin: '0 10px',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#CCD219',
    },
  },
  buttonEnd: {
    border: '1px solid #f50057',
    backgroundColor: '#fff',
    marginRight: '10px',
    color: '#f50057',
    '&:hover': {
      backgroundColor: '#f50057',
      color: '#fff',
    },
  },
  buttonWhatsApp: {
    color: '#fff',
    fontSize: '12px',
    borderRadius: '7px',
    border: '0',
    backgroundColor: '#25d366',
    '&:hover': {
      backgroundColor: '#1dba58',
      border: 0,
      color: '#fff',
    },
  },
  buttonBack: {
    color: '#fff',
    fontSize: '12px',
    borderRadius: '7px',
    border: '0',
    backgroundColor: '#CCD219',
    '&:hover': {
      backgroundColor: '#CCD219',
      border: 0,
      color: '#fff',
    },
  },
  pet: {
    fontSize: '18px',
    fontWeight: 'bold',
  },
  listServices: {
    width: 'min(600px, 100%)',
  },
  total: {
    textAlign: 'center',
    fontWeight: '400',
    display: 'block',
    fontSize: '18px',
    marginBottom: '-15px',
  },
  price: {
    border: '1px solid #CCCCCC',
    borderRadius: '5px',
    textAlign: 'center',
    width: '100%',
    fontWeight: '700',
    display: 'block',
    padding: '10px',
    fontSize: '36px',
  },
  subtitle: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#666666',
  },
  espaco: {
    margin: '15px 0',
  },
  customerDetails: {
    fontSize: '14px',
    color: '#878c93',
  },
  successTitle: {
    color: '#47A59D',
    fontSize: '36px',
    margin: 0,
    textAlign: 'center',
    marginTop: '30px',
  },
  back: {
    color: '#666',
    display: 'flex',
    fontSize: '14px',
    justifyContent: 'space-around',
    textDecoration: 'none',
    margin: '50px 5px',
  },
  assinante: {
    fontSize: '8px',
    background: '#f50057',
    color: '#fff',
    padding: '3px 6px',
    borderRadius: '37px',
    marginLeft: '20px',
  },
  discount: {
    marginBottom: '-10px',
    textDecoration: 'line-through',
    color: '#878c93',
    fontSize: '11px',
  },
}))

const LoadingContainer = withStyles(() => ({
  root: {
    color: '#fff',
    border: '0',
    backgroundColor: '#ffffff',
    marginTop: '20px',
  },
}))(Grid)

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

function Main({
  order,
  checklist,
  setOrder,
  employeeId,
  handleGetOrderUpdate,
  setLoading,
  loading,
}) {
  const { search } = useLocation()
  const dev = new URLSearchParams(search).get('dev')

  const { openModalCheckin, setOpenModalCheckin } =
    useContext(MainCheckinContext)
  const { modules } = useContext(AuthContext)

  const [errorToExport, setErrorToExport] = useState(false)
  const [currentTab, setCurrentTab] = useState(0)
  const [started, setStarted] = useState(false)
  const [selectedCheckList, setSelectedCheckList] = useState([])
  const history = useHistory()
  const [openModal, setOpenModal] = React.useState(null)
  const [agendaModal, setAgendaModal] = React.useState(null)
  const [error, setError] = React.useState(null)
  const [valueDescription, setValueDescription] = useState('')
  const [professional, setProfessional] = React.useState(null)
  const [buttonDisabled, setButtonDisabled] = React.useState(false)
  const [verificationStatus, setVerificationStatus] = React.useState(false)
  const [errorBackSchedule, setErrorBackSchedule] = React.useState(false)

  useEffect(() => {
    if (order?.pet.id) PetController(order?.pet.id)

    if (order?.customer.cpf) {
      RecommendationsBuilder({ cpf: order.customer.cpf })
    }

    usePrinter.getState().actions.setSchedule({
      date: order?.date,
      startAt: order?.startAt,
      orderId: order?.appointmentOrderId,
      comment: order?.comment,
    })

    usePrinter.getState().actions.setServices(_.groupBy(order?.services, 'employeeId'))

    usePrinter.getState().actions.setCustomer({
      cpf: order?.customer.cpf,
      name: order?.customer.name,
      phoneNumber: order?.customer.phoneNumber,
    })

  }, [order])

  const classes = useStyles()
  const { orderId } = useParams()


  const closeModal = () => {
    setOpenModal(false)
    setAgendaModal(false)
    setOpenModalCheckin(false)
  }

  const getProf = async () => {
    try {
      setButtonDisabled(true)
      const { data } = await API.get(
        'employees/search?status=ATIVO&executesService=true',
      )
      setProfessional(data?.results)
      setButtonDisabled(false)
    } catch (err) {
      console.log('Erro:', err)
    }
  }

  useEffect(() => {
    getProf()
  }, [])

  const postEdit = async (id, serviceOrderId) => {
    try {
      setButtonDisabled(true)
      await API.post(`service-order/${serviceOrderId}/employee`, {
        employee: id,
      })
      setButtonDisabled(false)
    } catch (err) {
      console.log('Erro:', err)
    }
  }

  const tabs = [
    {
      value: 0,
      label: 'CHECK-IN',
      show: true,
    },
    {
      value: 1,
      label: 'EM ANDAMENTO',
      show: false,
    },
    {
      value: 2,
      label: 'CHECK-OUT',
      show: true,
    },
    {
      value: 3,
      label: 'FATURA',
      show: true,
    },
  ]

  const handleCurrentTab = (value) => setCurrentTab(value)
  const handleGetOrder = async () => {
    setButtonDisabled(true)
    const data = await getOrder(orderId)
    setOrder(data)
    setButtonDisabled(false)
  }

  const handleCancel = async () => {
    try {
      setButtonDisabled(true)
      const result = await API.post(
        `service-order/appointment/${order?.id}/cancel`,
      )

      setOpenModal(result?.data?.message)
      setError(false)
      setVerificationStatus(true)
      setTimeout(() => {
        history.push('/agenda')
      }, 1500)
      setButtonDisabled(false)
    } catch (err) {
      setOpenModal(err?.response?.data?.friendly_message)
      setError(true)
    }
  }

  const getInitialTab = () => {
    // AGENDADO / EM ANDAMENTO / FINALIZADO OU CANCELADO / ORÇAMENTO ENVIADO
    const status = order?.status

    if (status && status.includes('ORÇAMENTO ENVIADO')) return 3
    if (status && status.includes('EM ANDAMENTO')) return 2
    return 0
  }

  const getStatus = () => {
    // AGENDADO / EM ANDAMENTO / FINALIZADO OU CANCELADO / ORÇAMENTO ENVIADO
    const status = order?.services.forEach((service) => {
      updateStatus(service.serviceOrderId, 'FINALIZADO')
    })

    return status
  }

  useEffect(() => {
    const tab = getInitialTab()
    setCurrentTab(tab)
    if (order) handleServices()
  }, [order])

  const [selectedServices, setSelectedServices] = useState([])
  const [services, setServices] = useState([])
  const [showAddServices, setShowAddServices] = useState(false)
  const [serviceModal, setServiceModal] = React.useState(false)
  const [titleModal, setTitleModal] = React.useState(null)
  const [deleteService, setDeleteService] = React.useState(null)
  const [loadingService, setLoadingService] = React.useState(false)
  const [openTaxidogDrawer, setOpenTaxidogDrawer] = useState(false)

  const handleNewServices = async () => {
    setButtonDisabled(true)
    setLoadingService(true)
    const services = [
      {
        serviceId: selectedServices?.id,
        employeeId,
        durationInMinutes: selectedServices?.defaultDurationInMinutes,
        status: 'FINALIZADO',
      },
    ]

    const data = await addItemOrder(order.appointmentOrderId, services)
    if (data?.status === 201) {
      setShowAddServices(false)
      setSelectedServices([])
      handleGetOrderUpdate()
    }

    setTimeout(() => {
      setLoadingService(false)
    }, 3000)
    setButtonDisabled(false)
  }

  function compareService(data) {
    const arrayFields = order?.services.map((item) => item.serviceId)
    const res = data.filter((item) => !arrayFields.includes(item.id))

    setServices(res)
  }

  async function handleServices() {
    setButtonDisabled(true)
    const data = await getServices(order?.pet?.id)
    compareService(data)
    setButtonDisabled(false)
  }

  useEffect(() => {
    usePrinter.getState().actions.setNewComment(null)
  }, [])

  useEffect(() => {
    if (selectedServices?.id) handleNewServices()
  }, [selectedServices])

  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const removeTaxidog = async (data) => {
    await API.delete(`taxi-dog/appointment/${order.appointmentOrderId}`, data)

    setTimeout(() => {
      window.location.reload()
    }, 500)
  }

  const handleDelete = async (id) => {
    setTitleModal('Deletar Serviço')
    setOpenModal('Você tem certeza que deseja deletar esse serviço?')
    setVerificationStatus(true)
    setError(true)
    setDeleteService(id)
  }

  const handleUpdate = async () => {
    setButtonDisabled(true)
    setLoadingService(true)
    updateStatus(deleteService, 'CANCELADO', handleGetOrderUpdate, closeModal())

    setTimeout(() => {
      setLoadingService(false)
    }, 3000)
    setButtonDisabled(false)
  }

  const handlePDV = async (id) => {
    try {
      setButtonDisabled(true)
      const result = await API.post(
        `service-order/appointment/${id}/pre-budget`,
      )
      setOpenModal(result?.data?.message)
      setError(false)
      setVerificationStatus(false)
      setButtonDisabled(false)
    } catch (err) {
      setOpenModal(err?.response?.data?.friendly_message)
      setError(true)
    }
  }

  const confirmPreSchedule = async (id) => {
    try {
      setButtonDisabled(true)
      const result = await API.post(`service-order/appointment/${id}/confirm`)
      setOpenModal(result?.data?.message)
      setError(false)
      setVerificationStatus(false)
      setAnchorEl(null)
      handleGetOrderUpdate()
      setButtonDisabled(false)
    } catch (err) {
      setOpenModal(err?.response?.data?.friendly_message)
      setError(true)
    }
  }

  const cancelRecurring = async (id) => {
    try {
      setButtonDisabled(true)
      const result = await API.post(
        `service-order/appointment/${id}/cancel-recurring`,
      )
      setOpenModal(result?.data?.message)
      setError(false)
      setVerificationStatus(false)
      setAnchorEl(null)
      handleGetOrderUpdate()
      setButtonDisabled(false)
    } catch (err) {
      setOpenModal(err?.response?.data?.friendly_message)
      setError(true)
    }
  }

  const backState = async () => {
    try {
      await API.post(`appointments/reset-status/${orderId}`)
      window.location.reload()
    } catch ({ response }) {
      setErrorBackSchedule(response.data.friendly_message)
    }
  }

  const updateTaxiDog = async (data) => {
    await API.post(`taxi-dog/appointment/${order.appointmentOrderId}`, data)
    setTimeout(() => {
      window.location.reload()
    }, 500)
  }

  const handleTextDescription = async (text) => {
    setValueDescription(text?.toString())
    try {
      await API.put(`service-order/appointment/${order?.id}/add-note`, {
        comment: text?.toString(),
      })
    } catch (err) {
      console.log('Erro:', err)
    }
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const serviceCheckinFn = useCallback(() => {
    setOpenModalCheckin(true)
    setStarted(true)
    createCheckin(
      order?.appointmentOrderId,
      selectedCheckList,
      valueDescription || order?.comment,
    )
    setTimeout(() => {
      handleGetOrder()
      getStatus()
      setOpenModalCheckin(false)
      setLoadingService(false)
      history.push('/agenda')
    }, 500)
  }, [order, selectedCheckList, valueDescription])

  return (
    <div>
      <Printer />
      <AgendaModal
        close={closeModal}
        open={agendaModal}
        handleClose={handleCancel}
        id={order?.id}
        serviceModal={serviceModal}
        date={order?.date}
        url="/agenda"
      />
      <DrawerType
        open={openTaxidogDrawer}
        setDrowerOpen={setOpenTaxidogDrawer}
        titleHeader="EDITAR TAXI DOG"
        anchor="right"
        content={
          <TaxiDogProvider>
            <TaxiDog
              defautlt={order?.taxiDog}
              customer={{
                ...order?.customer,
                customerDefaultAddress: order?.customerDefaultAddress,
              }}
              setTaxiDogData={(data) => {
                updateTaxiDog(data)
              }}
              close={() => {
                setOpenTaxidogDrawer(false)
              }}
            />
          </TaxiDogProvider>
        }
      />

      <ModalType
        open={openModal && true}
        close={closeModal}
        titleDescription={openModal}
        title={titleModal}
        handleClick={
          openModal === 'Você tem certeza que deseja deletar esse serviço?'
            ? handleUpdate
            : ''
        }
        err={error}
        oneButton={false}
        verification={verificationStatus}
      />

      <ModalType
        open={openModalCheckin}
        close={closeModal}
        title="Agendamento Iniciado"
        err={error}
        oneButton={false}
      />

      <Tabs
        value={currentTab}
        indicatorColor="primary"
        textColor="primary"
      // onChange={handleCurrentTab}
      >
        {tabs.map(
          (tab) =>
            tab.show && (
              <Tab
                key={tab.label}
                label={tab.label}
                value={tab.value}
                tabIndex={tab.value}
              />
            ),
        )}
      </Tabs>
      {(order?.status === 'ORÇAMENTO ENVIADO' ||
        order?.status === 'EM ANDAMENTO') && (
          <Grid
            item
            xs={12}
            style={{ top: '110px', right: '40px', position: 'absolute' }}
          >
            <Button
              startIcon={<ArrowBack />}
              type="submit"
              color="secondary"
              size="large"
              className={classes.buttonBack}
              onClick={backState}
            >
              voltar para agendado
            </Button>

            <Snackbar
              open={errorBackSchedule}
              autoHideDuration={3000}
              onClose={() => {
                setErrorBackSchedule(false)
              }}
            >
              <Alert severity="error">{errorBackSchedule}</Alert>
            </Snackbar>
          </Grid>
        )}
      {currentTab === 0 && (
        <>
          <Checkin
            order={order}
            checklist={checklist}
            selectedCheckList={selectedCheckList}
            setSelectedCheckList={setSelectedCheckList}
            started={started}
            employeeId={employeeId}
            setValueDescription={setValueDescription}
            handleGetOrderUpdate={handleGetOrderUpdate}
            setLoadingService={setLoadingService}
            loadingService={loadingService}
            setLoading={setLoading}
            loading={loading}
          />
          <Grid item xs={12} className='flex itens-center justify-center w-full gap-4'>
            <Button
              className={classes.buttonEnd}
              aria-describedby={id}
              variant="contained"
              color="primary"
              onClick={handleClick}
            >
              Mais opções
            </Button>
            <Menu
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              keepMounted
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: '26ch',
                },
              }}
            >
              {order?.status === 'PRE-AGENDADO' && (
                <MenuItem
                  key="confirm"
                  onClick={() => confirmPreSchedule(order.id)}
                >
                  Confirmar agendamento
                </MenuItem>
              )}
              <MenuItem
                key="modificar"
                onClick={() => {
                  setAgendaModal(true)
                  setServiceModal(true)
                }}
              >
                Modificar Data
              </MenuItem>
              <MenuItem key="enviar" onClick={() => handlePDV(order.id)}>
                Enviar para PDV
              </MenuItem>
              <MenuItem
                key="cancelar"
                onClick={() => {
                  setAgendaModal(true)
                  setServiceModal(false)
                }}
              >
                Cancelar Agendamento
              </MenuItem>
              <MenuItem key="confirm" onClick={() => cancelRecurring(order.id)}>
                Cancelar Recorrência
              </MenuItem>
            </Menu>
            <StartService
              disabled={loadingService || !(order?.services.length > 0)}
              token={order?.token}
              beforeClick={() => setLoadingService(true)}
              onClick={() => {
                serviceCheckinFn()
              }}
            />
          </Grid>
        </>
      )}
      {currentTab === 2 && (
        <>
          <Grid item xs={12}>
            <TableContainer>
              <Table size="medium" aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Serviço</TableCell>
                    <TableCell align="center">Valor</TableCell>
                    <TableCell align="center">Profissional</TableCell>
                    <TableCell align="center">Deletar</TableCell>
                  </TableRow>
                </TableHead>

                {/* SKELETON */}

                {order?.services.length > 0 && !loadingService ? (
                  <TableBody>
                    {order?.services.map((service) => (
                      <TableRow key={service.serviceName}>
                        <TableCell component="th" scope="row">
                          {service?.serviceName}
                          {service.subscriptionStatus && (
                            <span className={classes.assinante}>
                              ASSINATURA
                            </span>
                          )}
                          {service?.hasPackage && (
                            <span className={classes.assinante}>PACOTE</span>
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {service?.discount === 0 ? (
                            Intl.NumberFormat('pt-BR', {
                              style: 'currency',
                              currency: 'BRL',
                            }).format(service?.totalPrice)
                          ) : (
                            <>
                              <p className={classes.discount}>
                                {Intl.NumberFormat('pt-BR', {
                                  style: 'currency',
                                  currency: 'BRL',
                                }).format(service?.originalPrice)}
                              </p>

                              <p>
                                {Intl.NumberFormat('pt-BR', {
                                  style: 'currency',
                                  currency: 'BRL',
                                }).format(service?.totalPrice)}
                              </p>
                            </>
                          )}
                        </TableCell>

                        <TableCell component="th" scope="row" align="center">
                          <TextField
                            id="name"
                            select
                            fullWidth
                            label="Nome"
                            defaultValue={service.employeeName}
                            variant="outlined"
                          >
                            {professional?.map((item) => (
                              <MenuItem
                                key={item.name}
                                value={item.name}
                                onClick={() =>
                                  postEdit(
                                    item.employeeId,
                                    service.serviceOrderId,
                                  )
                                }
                              >
                                {item.name}
                              </MenuItem>
                            ))}
                          </TextField>
                        </TableCell>

                        <TableCell align="right">
                          <IconButton
                            style={{ color: 'red' }}
                            aria-label="delete"
                            onClick={() => handleDelete(service.serviceOrderId)}
                          >
                            <DeleteForever />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                ) : (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center">
                        <Skeleton />
                      </TableCell>
                      <TableCell align="center">
                        <Skeleton />
                      </TableCell>
                      <TableCell align="center">
                        <Skeleton />
                      </TableCell>
                      <TableCell align="center">
                        <Skeleton />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">
                        <Skeleton />
                      </TableCell>
                      <TableCell align="center">
                        <Skeleton />
                      </TableCell>
                      <TableCell align="center">
                        <Skeleton />
                      </TableCell>
                      <TableCell align="center">
                        <Skeleton />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">
                        <Skeleton />
                      </TableCell>
                      <TableCell align="center">
                        <Skeleton />
                      </TableCell>
                      <TableCell align="center">
                        <Skeleton />
                      </TableCell>
                      <TableCell align="center">
                        <Skeleton />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">
                        <Skeleton />
                      </TableCell>
                      <TableCell align="center">
                        <Skeleton />
                      </TableCell>
                      <TableCell align="center">
                        <Skeleton />
                      </TableCell>
                      <TableCell align="center">
                        <Skeleton />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Grid>

          <div className={classes.espaco}>
            <Grid item xs={12}>
              <Button
                color="secondary"
                startIcon={<AddCircleIcon />}
                onClick={() => setShowAddServices(true)}
                style={{ fontSize: '12px', fontWeight: 'bold' }}
              >
                Adicionar mais serviços
              </Button>
            </Grid>

            <Button
              startIcon={<DirectionsCar />}
              onClick={() => setOpenTaxidogDrawer(true)}
              style={{ fontSize: '12px', fontWeight: 'bold', color: '#EFD90F' }}
            >
              {!order?.taxiDog?.driverPickupType
                ? 'Adicionar Taxi Dog'
                : 'Editar Taxi Dog'}
            </Button>
            {order?.taxiDog?.driverPickupType && (
              <>
                <p style={{ margin: '0 0 0 6px' }}>
                  <strong>Motorista: </strong> {order?.taxiDog?.driverName}
                </p>
                <p style={{ margin: '0 0 0 6px' }}>
                  <strong>Valor: </strong>
                  {order?.taxiDog?.total <= 0 && (
                    <span
                      style={{
                        background: '#f00',
                        borderRadius: '7px',
                        padding: '0px 10px',
                        color: '#fff',
                        fontWeight: 'bold',
                      }}
                    >
                      Pacote
                    </span>
                  )}
                  {order?.taxiDog?.total > 0 &&
                    Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    }).format(order?.taxiDog?.total)}
                </p>
                <p style={{ margin: '0 0 0 6px' }}>
                  <strong>Receber: </strong> {order?.taxiDog?.driverPickupType}
                </p>
                <p style={{ margin: '0 0 0 6px' }}>
                  <strong>Tipo: </strong> {order?.taxiDog?.taxiDogType}
                </p>
                <p
                  onClick={() => {
                    removeTaxidog()
                  }}
                  style={{
                    margin: '0 0 0 6px',
                    color: '#F00E0E',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                >
                  REMOVER TAXI DOG
                </p>
              </>
            )}
          </div>
          <Recommendation.Root className='my-4' />
          {showAddServices && (
            <Grid item xs={12}>
              <Autocomplete
                options={services}
                getOptionLabel={(option) => option.name}
                renderOption={(option) => (
                  <>
                    {option.internalCode} -{option.name} R$
                    {option.price}
                  </>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Serviços adicionais"
                    placeholder="Procurar serviços adicionais"
                    margin="normal"
                    variant="outlined"
                  />
                )}
                onChange={(event, value) => {
                  setSelectedServices(value)
                }}
              />
            </Grid>
          )}

          {order && (
            <Grid
              item
              xs={12}
              style={{ marginTop: '10px', marginBottom: '10px' }}
            >
              <TextField
                id="description"
                name="description"
                label="Descrição"
                defaultValue={order?.comment}
                multiline
                rows={4}
                fullWidth
                variant="outlined"
                onChange={(e) => {
                  usePrinter.getState().actions.setNewComment(e.target.value?.toString() || null)
                }}
                onBlur={({ target }) => handleTextDescription(target.value)}
              />
            </Grid>
          )}

          {buttonDisabled ? (
            <LoadingContainer>
              <CircularProgress />
            </LoadingContainer>
          ) : (
            <>
              <div className='flex itens-center justify-center w-full gap-4'>
                <Action.Root
                  type="submit"
                  disabled={buttonDisabled}
                  onClick={async () => {
                    setButtonDisabled(true)
                    const { response: { data: { error = false } = {} } = {} } =
                      await exportToPDV(order?.appointmentOrderId)

                    if (!error) {
                      handleGetOrder()
                      setTimeout(() => {
                        handleCurrentTab(3)
                      }, 500)
                    }

                    console.log(error, 'error')

                    if (error) {
                      setButtonDisabled(false)
                      setErrorToExport(true)
                    }
                  }}
                >
                  FINALIZAR O SERVIÇO
                </Action.Root>
                <ChecklistPrint
                  disabled={loadingService || !(order?.services.length > 0)}
                  token={order?.token}
                />
              </div>
            </>
          )}
          <Snackbar
            open={errorToExport}
            autoHideDuration={6000}
            onClose={() => setErrorToExport(false)}
          >
            <Alert severity="error">Erro ao enviar para o ERP!</Alert>
          </Snackbar>
        </>
      )
      }
      {
        currentTab === 3 && (
          <>
            <Grid item xs={12}>
              <p className={classes.successTitle}>Serviço Finalizado</p>
            </Grid>
            <Grid item xs={12}>
              <span className={classes.subtitle}>Resumo</span>
            </Grid>

            <Grid item xs={12}>
              <TableContainer>
                <Table size="medium" aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Serviço</TableCell>
                      <TableCell align="center">Valor</TableCell>
                      <TableCell align="center">Status</TableCell>
                      <TableCell align="center">Profissional</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {order?.services.map((service) => (
                      <TableRow key={service.serviceName}>
                        <TableCell component="th" scope="row">
                          {service?.serviceName}
                          {service.subscriptionStatus && (
                            <span className={classes.assinante}>ASSINATURA</span>
                          )}
                          {service?.hasPackage && (
                            <span className={classes.assinante}>PACOTE</span>
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {service?.discount === 0 ? (
                            Intl.NumberFormat('pt-BR', {
                              style: 'currency',
                              currency: 'BRL',
                            }).format(service?.totalPrice)
                          ) : (
                            <>
                              <p className={classes.discount}>
                                {Intl.NumberFormat('pt-BR', {
                                  style: 'currency',
                                  currency: 'BRL',
                                }).format(service?.originalPrice)}
                              </p>

                              <p>
                                {Intl.NumberFormat('pt-BR', {
                                  style: 'currency',
                                  currency: 'BRL',
                                }).format(service?.totalPrice)}
                              </p>
                            </>
                          )}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          {service.status}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          {service.employeeName}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            {order?.comment && (
              <Grid item xs={12} style={{ marginTop: '20px' }}>
                <p className={classes.subtitle}>Descrição</p>
                <span style={{ fontSize: '13px' }}>{order?.comment}</span>
              </Grid>
            )}
            {order?.taxiDog.driverPickupType && (
              <LineTaxidog {...order?.taxiDog} />
            )}

            <Recommendation.Root className='my-4' />

            <Grid item xs={12}>
              <Link to="/agenda" className={classes.back}>
                VOLTAR PARA AGENDA
              </Link>
            </Grid>
          </>
        )
      }
    </div >
  )
}

export default Main
