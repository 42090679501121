
import { create } from 'zustand'
import { produce } from 'immer'

const defaultData = {
  recommendations: [],
}
export const useGaida = create((set) => ({
  data: defaultData,
  actions: {
    setRecommendations: (recommendations) =>
      set((state) =>
        produce(state, (draft) => {
          draft.data.recommendations = recommendations
        }),
      ),
  },
}))
