import { Ham, Lollipop, PawPrint, BriefcaseMedical } from "lucide-react";
import { Pinwheel, ToiletPaper } from "@phosphor-icons/react";


export function DepartmentIcon({ icon }) {
  if (icon.type === 'icon') {
    switch (icon.name) {
      case 'ham':
        return <Ham className="h-10 w-10" />
      case 'snack':
        return <Lollipop className="h-10 w-10" />
      case 'toilet-paper':
        return <ToiletPaper className="h-10 w-10" />
      case 'toy':
        return <Pinwheel className="h-10 w-10" />
      case 'medicine':
        return <BriefcaseMedical className="h-10 w-10" />
      default:
        return <PawPrint className="h-10 w-10" />
    }
  }
  if (icon.type === 'img') {
    return <img src={`/img/print/${icon.src}.png`} className="h-10 w-10" />
  }
}
