import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import CssBaseline from '@material-ui/core/CssBaseline'
import Menu from '../components/organisms/Menu'
import Submenu from '../components/organisms/Submenu'
import { Alert } from '../components/ui/Alert'
import { Alerts } from "./global/alerts";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    background: "transparent",
    boxShadow: "none",
    zIndex: 15,
  },
  toolbar: {
    alignItems: "center",
    color: "#666666",
    display: "flex",
    fontWeight: "bold",
    justifyContent: "space-between",
    padding: 0,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(4),
    overflowX: "hidden",
  },
}));

function BasePage({ children, pageTitle, extendStyles }) {
  const classes = useStyles()

  return (
    <>
      <Alerts.LgpdAlert />
      <Alert.Root name='documentAssAlert'>
        <Alert.Content>
          <Alert.Title>Atenção!</Alert.Title>
          <Alert.Description>
            <p>
              Conforme comunicamos na última live, com o objetivo de melhorar o fluxo de caixa entre franqueadora e franqueado, incluindo <strong>Split de assinatura</strong>, <strong>Royalties</strong> e <strong>Fundo de Marketing</strong>, você receberá em seu e-mail o <strong>Termo de Adesão</strong> para sua assinatura pela plataforma Docusign e  que deverá ser assinada dentro do prazo de <strong>até 7 dias</strong>.
            </p>

            <p>
              Atenciosamente,<br /> Departamento Financeiro.
            </p>
          </Alert.Description>
          <Alert.Actions>
            <Alert.Confirm
              onClick={() => {
                isOpen('documentAssAlert', false)
                localStorage.setItem('documentAssAlert', 'true')
              }}
            >
              Confirmar e fechar
            </Alert.Confirm>
          </Alert.Actions>
        </Alert.Content>
      </Alert.Root>

      <div className={classes.root} style={{ height: '100%' }}>
        <ToastContainer />
        <CssBaseline />
        <Menu />
        <main className={classes.content} style={extendStyles?.main}>
          <AppBar
            position="static"
            className={classes.appBar}
            style={extendStyles?.appBar}
          >
            <Toolbar className={classes.toolbar}>
              <h1>{pageTitle}</h1>
              <Submenu />
            </Toolbar>
          </AppBar>
          {children}
        </main>
      </div>
    </>
  )
}

export default BasePage;
