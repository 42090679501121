import _ from "lodash";
import { apiSimple } from "../../api";
import { TERMS_PATHS } from "../../../store/global/constants";
import { clearNumber, promiseDelay } from "../../functions";

export default class TermController {

  constructor(name) {
    this._signed = null;
    this._term = name;

    if(!TERMS_PATHS[this._term]) throw new Error('Termo não encontrado')

    this._paths = TERMS_PATHS[this._term];

    this.init()
  }

  init = _.once(() => {
    this.signedCheck();
  });


  async signedCheck (){
    const { data } = await apiSimple.get(this._paths.get)

    this._signed =  data.aceiteOk
  };

  async sendSigning({ cpf, menloTerm, consentTerm }){
    const { data } = await apiSimple.post(this._paths.post, {
      cpf: clearNumber(cpf),
      aceite1: !!menloTerm,
      aceite2: !!consentTerm,
    })

    this._signed =  data.aceiteOk
  };

  async setSigned(fn){
    if (this._signed !== null) {
      return fn(this._signed)
    }

    await promiseDelay(300)
    this.setSigned(fn)
  }
}
