import _ from "lodash"
import { Coins, ShoppingCart, ListCollapse } from "lucide-react"
import { formatMoney, formatName } from "../../../../services/functions"
import { useGaida } from "../../../../store/global/useGaida"
import { twMerge } from "tailwind-merge"

import { useMerchant } from "../../../../store/global/useMerchant"
export function RecommendationRoot({ className, ...rest }) {
  const gaidaRecommendations = useGaida(st => st.data.recommendations)
  const merchantModules = useMerchant(st => st.data.merchant.modules)

  if (!_.some(merchantModules, { id: 'gaida' })) return null
  if (!_.size(gaidaRecommendations)) return null

  const recommendations = _.take(gaidaRecommendations, 4);

  return (
    <div className={twMerge("w-full flex flex-col gap-2", className)} {...rest}>
      <h2 className="text-sky-800 font-semibold text-base flex items-center">
        <ShoppingCart className="w-5 h-5 mr-2" />
        Sugestão de produtos
      </h2>
      <div className="w-full grid grid-auto-fit-[200px] group">
        {recommendations.map((recommendation) => (
          <div className="flex transition-all ease-out duration-200 m-1.5 origin-center group-hover:grayscale-[0.5] hover:!grayscale-0 hover:scale-105 group/box">
            <div className="flex flex-col w-full flex-1 gap-4 rounded-md shadow-md border border-zinc-200/40 p-4 group-hover/box:shadow-xl transition-all ease-out duration-200">
              <h3 className="w-full text-scale-500 relative z-[1] font-semibold text-center text-base h-6">{recommendation.department}</h3>
              <div className="flex items-center flex-1 bg-white h-full w-full flex-col gap-2">
                <div className="w-28 h-28 flex items-end justify-center">
                  <img src={`/img/recommendation/${recommendation.icon.src}.jpg`} className="max-w-full max-h-full" />
                </div>
                <p className="text-zinc-800 text-xs font-semibold tracking-wide">
                  {recommendation.name}
                </p>
              </div>
              <div className="flex flex-col gap-2 w-full">
                <p className="flex items-center text-zinc-500 text-xs">
                  <ListCollapse className="mr-2 w-4 h-4 " />
                  {recommendation.category}</p>
                <p className="flex items-center text-zinc-500 text-xs">
                  <Coins className="mr-2 w-4 h-4" />
                  R$ {formatMoney(recommendation.price)}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
