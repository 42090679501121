import _ from "lodash";
import { gaida } from "../api";
import { formatResponse } from "../formatResponses/global";
import { clearNumber } from "../functions";
import { mock } from "../../mock";
import { queryClient } from "../react-query";
import { useGaida } from "../../store/global/useGaida";
import { useMerchant } from "../../store/global/useMerchant";

const gaidaActions = useGaida.getState().actions

export default class RecommendationsBuilder {
  constructor(props) {
    this._cpf = props.cpf
    this.getRecommendations()
  }

  async getRecommendations() {
    const storeCnpj = useMerchant.getState().data.merchant.cnpj
    const recommendationsFn = process.env.NODE_ENV === 'development' ? mock.gaida.get.suggestion_customer : () => gaida.get(`/suggestion/store/${storeCnpj}/customer/${clearNumber(this._cpf)}`)

    const { data } = await queryClient.fetchQuery({
      queryKey: ['gaida-recommendation'],
      queryFn: recommendationsFn,
      retry: false,
      staleTime: Infinity,
    })

    const formattedRecommendations = formatResponse.customerRecommendations(data.suggestions);
    gaidaActions.setRecommendations(formattedRecommendations)
  };
}
