import _ from "lodash";
import moment from "moment";
import { createId as cuid } from "@paralleldrive/cuid2";

import * as dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import 'dayjs/locale/pt-br'
dayjs.extend(relativeTime)
dayjs.locale('pt-br')

import { ATTRIBUTES_NAME } from "../../styles/constant";
import {
  abbreviate,
  checkPetRegistration,
  clearNumber,
  dateFormatedToDB,
  formatAttributeValue,
  formatCellPhoneNumber,
  formatCpf,
  formatGaidaRecommendationCategory,
  formatMerchantName,
  formatMoney,
  formatName,
  formatPetServicesPackage,
  formatZipCode,
  getAcronymName,
  getAgeByBirth,
  getDepartmentName,
  getDepartmentNameValue,
  getRecommendationDepartment,
  getRecommendationDepartmentIcon,
  getUserType,
  getUserTypeLevel,
  mountAddress,
  splitName
} from "../functions";
import slugify from "slugify";

function formatUser(employee) {
  const fuillName = formatName(employee.name)
  const splittedName = splitName(fuillName)
  const abbreviateName = abbreviate(fuillName)
  const acronymName = getAcronymName(fuillName)


  return {
    email: employee.email,
    status: employee.active,
    crmv: employee.crmv || '',
    phone: formatCellPhoneNumber(employee.phone),
    groupId: employee.employeeGroupId,
    department: getDepartmentNameValue(employee.department),
    name: {
      full: fuillName,
      acronym: acronymName,
      abbreviate: abbreviateName,
      firstName: splittedName.firstname,
      lastName: splittedName.lastname,
    },
  }
}

function formatUserGroup(group) {
  return {
    id: group.id,
    name: formatName(group.name),
    description: group.description,
    type: getUserType(group.name),
    level: getUserTypeLevel(group.name)
  }
}


function formatMerchant(merchant) {
  const merchantName = formatMerchantName(merchant.name);
  const departmentName = getDepartmentName();

  const modules = merchant.modules.map(module => ({
    name: module.name,
    id: slugify(module.name.toLowerCase())
  }))

  const plans = merchant.planTypes.map(plan => ({
    name: plan,
    id: slugify(plan.toLowerCase())
  }))

  return {
    plans,
    modules,
    id: merchant.storeId,
    cnpj: merchant.cnpj || "00000000000000",
    name: {
      storeName: merchantName,
      department: departmentName,
      fullName: `${departmentName} ${merchantName}`
    },
    location: {
      uf: merchant.location.uf || "",
      city: merchant.location.city || "",
      postalCode: merchant.location.postalCode || "",
      street: merchant.location.street || "",
      number: merchant.location.number || "",
      complement: merchant.location.complement || "",
      neighborhood: merchant.location.neighborhood || "",
    },
    contact: {
      phone: clearNumber(merchant.contact.phoneNumber),
      whatsapp: clearNumber(merchant.contact),
    },
  };
}


function formatPet(pet) {
  const petAttributes = _.fromPairs(
    _.map(
      pet.attributes,
      attribute => [
        ATTRIBUTES_NAME[attribute.label],
        formatAttributeValue(attribute.value)
      ]
    )
  );

  const name = formatName(pet.petName)
  const weight = formatMoney(petAttributes.weight, 3);
  const packages = _.groupBy(formatPetServicesPackage(pet.packages.services), 'department');

  const isCompleteRegistration = checkPetRegistration(pet)

  const birthDate = moment(`${dateFormatedToDB(pet.birthdate)} 00:00:00`);
  const age = getAgeByBirth(birthDate);

  const customer = pet.customer;
  const customerName = formatName(customer.fullName)
  const customerAbbreviateName = abbreviate(customer.fullName)
  const customerAcronymName = getAcronymName(customer.fullName)

  const {
    firstname: customerFirstName,
    lastname: customerLastName
  } = splitName(customer.fullName)

  const customerCpf = pet.alreadyClient
    ? formatCpf(pet.customer.cpf)
    : pet.customer.cpf;

  const customerPhone = pet.alreadyClient
    ? formatCellPhoneNumber(customer.phoneNumber)
    : customer.phoneNumber;

  const customerSplittedAddress = {
    city: formatName(customer.customerDefaultAddress?.city),
    postalCode: formatZipCode(customer.customerDefaultAddress?.postalCode),
    street: formatName(customer.customerDefaultAddress?.street),
    country: formatName(customer.customerDefaultAddress?.country),
    complement: formatName(customer.customerDefaultAddress?.complement),
    number: customer.customerDefaultAddress?.number,
    neighborhood: formatName(customer.customerDefaultAddress?.neighborhood),
    uf: customer.customerDefaultAddress?.uf,
  }

  const customerAddress = mountAddress(customerSplittedAddress)

  return {
    name,
    packages,
    isCompleteRegistration,
    isClient: !!pet.alreadyClient,
    id: pet.id,
    photo: pet.petPhoto,
    note: pet.comments,
    status: pet.active,
    petAttributes: {
      ...petAttributes,
      birthDate: birthDate.format("DD/MM/YYYY"),
      age: age.formattedAge.full
    },
    badges: {
      alreadyClient: !!pet.alreadyClient,
      subscription: !!pet.subscription?.petSubscription,
      packages: !!pet.packages.services.length,
    },
    owner: {
      id: customer.id,
      cpf: customer.cpf,
      phone: customer.phoneNumber,
      email: customer.email,
      noMask: {
        cpf: formatCpf(customer.noMask.cpf),
        email: customer.noMask.email,
        phone: formatCellPhoneNumber(customer.noMask.phoneNumber)
      },
      name: {
        full: customerName,
        abbreviate: customerAbbreviateName,
        acronym: customerAcronymName,
        firstName: customerFirstName,
        lastName: customerLastName,
      },
      address: {
        full: customerAddress.full,
        blocks: customerAddress.blocks,
        splitted: customerSplittedAddress
      }
    },
    subscription: {},
    attributes: [
      ...pet.attributes.filter(attribute => attribute.label),
      { label: "Nome", value: name },
      { label: "Nascimento", value: birthDate.format("DD/MM/YYYY") },
      { label: "Idade", value: age.formattedAge.full },
      { label: "Tutor", value: customerAbbreviateName },
      { label: "TutorFull", value: customerName },
      { label: "TutorCpf", value: formatCpf(customer.noMask.cpf) },
      { label: "TutorPhone", value: formatCellPhoneNumber(customer.noMask.phoneNumber) },
      { label: "TutorEmail", value: customer.noMask.email },
      { label: "TutorAddress", value: customerAddress.full },
      { label: "PetPeso", value: weight },
    ],
  }
}

function formatServiceHistory(serviceHistory) {
  const services = serviceHistory.map(service => {
    const dbDate = dateFormatedToDB(service.lastPurshace)
    const isValid = dayjs(dbDate).isValid()
    const dateMsg = isValid ? dayjs(dbDate).fromNow() : service.lastPurshace

    return {
      ...service,
      dateMsg
    }
  })

  return {
    services
  }
}

function formatPetWeightHistory(weightHistory) {
  const chartWeights = _.reduce(weightHistory, (result, item) => {
    result.dates.push(item.date);
    result.weights.push(item.weight);
    return result;
  }, { dates: [], weights: [] });

  const tableWeights = _.map(weightHistory, item => ({
    weight: item.weight,
    date: item.date,
    id: cuid()
  }));

  return { chartWeights, tableWeights }
}

function formatMedicineData(medicineData) {
  const presentations = !!medicineData.apresentacoesConcentracoes[0]
    ? medicineData.apresentacoesConcentracoes[0].apresentacoesEConcentracoes
    : [];
  const frequency = !!medicineData.adminitracoesDoses[0]
    ? medicineData.adminitracoesDoses[0].frequenciaDeUtilizacao
    : null;
  const note = !!medicineData.adminitracoesDoses[0]
    ? medicineData.adminitracoesDoses[0].observacoes
    : null;

  const usage = !!medicineData.adminitracoesDoses[0]
    ? medicineData.adminitracoesDoses[0].vias
    : [];

  const activePrinciples = !!medicineData.sobre.principiosAtivos
    ? medicineData.sobre.principiosAtivos
    : [];

  const prescriptionType = !!medicineData.sobre.receita
    ? medicineData.sobre.receita
    : null;
  const brand = !!medicineData.marca ? medicineData.marca : null;

  return {
    id: medicineData._id,
    name: medicineData.nome,
    presentations,
    suggestions: {
      frequency,
      note,
      usage,
    },
    information: {
      activePrinciples,
      prescriptionType,
      brand,
    },
  };
}


function formatCustomerRecommendations(data) {
  return data.map(suggestion => {
    return {
      id: slugify(suggestion?.department.toLowerCase()),
      name: suggestion?.product.name,
      brand: suggestion?.product.brand,
      price: suggestion?.product.price,
      subcategory: suggestion?.product.subcategory,
      category: formatGaidaRecommendationCategory(suggestion?.product.category),
      department: getRecommendationDepartment(suggestion?.department),
      icon: getRecommendationDepartmentIcon(suggestion?.department),
      raw: suggestion
    }
  })
}

export const formatResponse = {
  user: (employee) => formatUser(employee),
  group: (group) => formatUserGroup(group),
  groups: (groups) => {
    if (!groups.length) return [];
    return groups.map(formatUserGroup);
  },
  merchant: (merchant) => formatMerchant(merchant),
  merchants: (merchants) => {
    if (!merchants.length) return [];
    return merchants.map(formatMerchant);
  },
  medicine: (medicine) => formatMedicineData(medicine),
  medicines: (medicines) => {
    if (!medicines) return [];
    return medicines.map(formatMedicineData);
  },
  pet: (pet) => formatPet(pet),
  petServiceHistory: (serviceHistory) => formatServiceHistory(serviceHistory),
  pets: (pets) => {
    if (!pets.length) return [];
    return pets.map(formatPet);
  },
  petWeightHistory: (weightHistory) => formatPetWeightHistory(weightHistory),
  customerRecommendations: (recommendations) => formatCustomerRecommendations(recommendations),
};
